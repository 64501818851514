body, html {
  width: 100%;
  height: 100%;
}

body {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  line-height: 28px;
  margin: 0;
  text-align: left;
  background-color: #fff;
  font-weight: 300;
  color: #6a7695;
  overflow-x: hidden;
}


p {
  color: #3e3e3e;
  font-family: "Montserrat", sans-serif;
  line-height: 28px;
  font-size: 15px;
}
.container { max-width: 1200px;}
.navbar-brand { display: none;}
.formobile { display: none !important;}
/*--------------------
 TYPOGRAPHY
-----------------*/
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: .5rem;
  color: #2f2f2f;
}

.h1,
h1 {
  font-size: 36px;
  font-weight: 900;
}

.h2,
h2 {
  font-size: 32px;
}

.h3,
h3 {
  font-size: 24px;
}

.h4,
h4 {
  font-size: 16px;
}

.h5,
h5 {
  font-size: 18px;
}

.h6,
h6 {
  font-size: 16px;
}

.text-sm {
  font-size: 14px;
}

.h1 a,
.h2 a,
.h3 a,
.h4 a,
.h5 a,
.h6 a,
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: #263b5e;
}

.h1 a:hover,
.h2 a:hover,
.h3 a:hover,
.h4 a:hover,
.h5 a:hover,
.h6 a:hover,
h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
  color: #F08100;
}

.dosage { text-align: center;}
.dosage img { border-radius: 100%; border:dashed 1px #0c9445; padding: 20px; width: 200px; height: 200px;}

.ayurveda { text-align: center; font-size: 1.5em;  text-transform: uppercase; padding: 10px 0;}
.ayurveda-img { background: #fffced; 
  border-radius: 100%; 
  width: 250px; 
  height: 250px; 
  text-align: center; 
  line-height: 250px; 
  position: relative;  
  z-index: 1;
  border: 0.5px dashed #6caaa8;
  border-radius: 100%;
  margin: 0 auto;

}
.ayurveda-img img { width: 100px; height: 100px; }
.roundedayurveda ul { padding-top: 55px;}
.roundedayurveda ul li img { width: 32px; height: 32px; border: 0.5px dashed #6caaa8; margin: 0 10px;
  border-radius: 100%;}
.roundedayurveda ul li {
  margin-bottom: 20px;
  font-weight: 700;
  position: relative;
  text-transform: uppercase;
  color: #0c9445;
}

.ayurveda-left ul li {
  text-align: right;
  padding-left: 100px;
  
}
.ayurveda-right ul li {
  text-align: left;
  padding-right: 100px;
}
.ayurveda-right ul li:after{
  left: 0;
}
.ayurveda-left ul li:after {
  right: 0;
}

.roundedayurveda ul li:after {
    position: absolute;
    content: '';
    width: 110px;
    border: 0.5px dashed #6caaa8;
    top: 0;
    bottom: 0;
    height: 1px;
    margin: auto 0 auto 0;
}
.ayurveda-left ul li:after {
  margin: auto -110px auto 0  !important;
}
.ayurveda-right ul li:after {
  margin: auto 0 auto -110px  !important;
}
/*-------------------
BUTTONS STYLE
----------------=----*/
.btn {
  font-weight: 700;
  position: relative;
  text-transform: uppercase;
  padding: 10px 35px;
  border-radius: 22px;
  font-size: 14px;
  letter-spacing: .5px;
  font-family: "Montserrat", sans-serif;
  border: 2px solid transparent;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
  transition: all 0.3s ease-in-out;
}

.btn i {
  display: inline-block;
  padding-left: 18px;
}

.btn-small {
  padding: 12px 25px;
}

.btn:focus {
  box-shadow: none !important;
}

.btn-main {
  background: #F08100;
  color: #fff;
  border-color: #F08100;
}

.btn-main:hover {
  color: #fff;
  border-color: #408858;
  background: #408858;
}

.btn-main2 {
  background: #d12030;
  color: #fff;
  border-color: #d12030;
}

.btn-main2:hover {
  background: #408858;
  color: #fff;
  border-color: #408858;
}

.btn-border {
  background: transparent;
  color: #b21625;
  border-color: #ff0000;
}

.btn-border:hover {
  color: #fff;
  border-color: #ff0000;
  background: #ff0000;
}

a {
  color: #d12030;
  outline: none;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

a:hover {
  color: #408858;
  outline: none;
  text-decoration: none;
}

a:focus {
  text-decoration: none;
}

a:focus, a:visited {
  outline: none;
}

ul, ol {
  margin-bottom: 0px;
  list-style-type: none;
  padding-left: 0px;
}

.pt-100 {
  padding-top: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

.p-200 {
  padding: 0px 200px;
}

.form-control {
  transition: all 0.3s ease-in-out;
}

.form-control:focus {
  box-shadow: none;
}

.shop-now-scroller {
  float: right;
  height: 66px;
  z-index: 1000;
  right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  bottom: 130px;
  
}
.shop-now-scroller i { padding: 5px 0 0 0 !important; }

/* FAQs */
#accordion { display: block; width: 90%; margin: 0 5%;}
.card-header {
  padding: 0;
  margin-bottom: 0;
  background-color: #f9f9f9;
  border-bottom: none;
  width: 100%;
  display: block;
}

.card { border: none;}
.faqsHeader { border-left: solid 1px #ea3442 ; padding-right: 25px; }
.faqsHeader a { display: block; 
  font-size: 1.5em;
  font-weight: 500px;
  color: #3f3f3f;
  padding: 30px 20px;
  background: url('../images/faq-open.png') right center no-repeat;
}
.card .process-icon img { border: dashed 1px #ccc; border-radius: 50%;}
.faqsHeader a.collapsed {background: url('../images/faq-closed.png') right center no-repeat !important;}
.card-body { border-left: solid 1px #b0b0b0 ; min-height: 20px !important; color: #000;}
.card { margin: 15px 0; border-bottom: dashed 1px #ccc;}
/*====================
Banner Area
======================*/
.section-padding {
  padding: 100px 0px;
}

.page-wrapper {
  padding: 100px 0px;
}

.section-heading {
  margin-bottom: 80px;
  text-align: center;
  position: relative;
}

.section-heading .subheading {
  font-size: 100px;
  opacity: .15;
  position: absolute;
  top: -17px;
  left: 0px;
  right: 0px;
  -webkit-text-fill-color: white;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #408858;
  z-index: -1;
}

.section-heading h2 {
  font-size: 32px;
  letter-spacing: -2px;
  line-height: 1.1;
  margin-top: 10px;
}



.subtitle {
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 2px;
  color: #408858;
  position: relative;
  font-weight: 600;
}

.page-header {
  padding: 150px 0px 50px;
  position: relative;
  background: #d12030 url('../images/bg/icon-bg.png') 50% 60% no-repeat;
  text-align: center;
  position: relative;
}

.page-header:before {
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  border-top: solid 10px #fff;
  top:91px;
}

.page-header .block h1 {
  color: #fff;
}

.page-header .block ul li {
  text-transform: uppercase;
  font-size: 14px;
  color: #fff;
}

.page-header .block ul li a {
  color: #fff;
}


/*------------------
    Feature
-------------------*/
.owl-nav .owl-prev { position:absolute; top: 35% ; left:0px;}
.owl-nav .owl-next { position:absolute; top: 35% ; right:0px;}
.owl-prev i, .owl-next i {color: #0c9445;}



/*-------------------
   MENU
----------------------*/
.site-navigation {
  position: absolute;
  top: 0;
  z-index: 9;
  width: 100%;
  background: #fff;
}
#shonow span { border-radius: 50px; border: dashed 1px  #d12030;  font-weight:700; display: inline-block; padding: 0 10px;}
.logotop { top: -10px; height: 92px;}
.logotop img{ max-width: 165px;}
.navbar {
  padding: 0;
}
.socialbutton { top: 10px; right: 0;}
.list-inline-item1 { display: inline;}
.navbar-nav .nav-link {
  padding-top: 20px !important;
  padding-right: 10px !important;
  padding-left: 10px !important;
  padding-bottom: 25px;
  font-weight: 700;
  text-transform: capitalize;
  font-size: 14px;
  color: #222;
  
}

.navbar-nav li.active a {  color: #d12030;}

.navbar-nav .nav-link:hover {
  color: #d12030;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  content: "";
  vertical-align: middle;
  background: no-repeat 50%;
  background-size: 100% 100%;
}

.header-contact-icon {
  width: 50px;
  height: 50px;
  background: #408858;
  text-align: center;
  margin-right: 20px;
  border-radius: 32px;
  color: #fff;
  font-size: 20px;
  padding-top: 12px;
}

.contact-text span {
  font-size: 14px;
}

.contact-text h4 {
  font-weight: 700;
}

/*-------------------
  DROPDOWN MENU
----------------------*/
.dropdown-menu {
  opacity: 0;
}

.dropdown-item {
  font-size: 14px;
  font-weight: 700;
  padding: 13px 20px;
  transition: all .4s;
  text-transform: capitalize;
  border-left: 2px solid transparent;
  opacity: .7;
}

.dropdown-item:hover {
  text-decoration: none;
  padding-left: 20px;
  opacity: 1;
  background: #f5f5f5;
}

.dropdown-toggle::after {
  display: none;
}

.nav-item.dropdown .dropdown-menu {
  transition: all 300ms ease;
  display: block;
  visibility: hidden;
  opacity: 0;
  top: 120%;
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  border: 0px;
  padding: 0px;
  position: absolute;
  min-width: 265px;
}

.nav-item.dropdown:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: 100%;
}

.menu_fixed {
  background: #fff;
  position: fixed;
  top: 0px;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
}

.menu_fixed .header-contact-icon {
  background: #F08100;
  color: #fff;
}

.site-navigation2 .navbar-nav .nav-link {
  text-transform: uppercase;
}

/* ================
 BAnner
================*/
.banner {
  position: relative;

}

.banner .banner-content {
  padding: 135px 0px 0px;
}

.banner .banner-content span.subheading {
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 2px;
  color: #408858;
  position: relative;
  padding-left: 60px;
  font-weight: 600;
}

.banner .banner-content span.subheading:before {
  position: absolute;
  top: 8px;
  left: 0;
  content: '';
  display: block;
  width: 40px;
  height: 2px;
  background: #408858;
}

.banner .banner-content h1 {
  font-size: 75px;
  margin: 25px 0px 30px;
  font-weight: 500;
  letter-spacing: -2px;
  line-height: 1.1;
  font-family: "Montserrat", sans-serif;
  color: #252525;
}

.banner .banner-content p {
  padding-right: 160px;
  margin-bottom: 40px;
}

.banner-img {
  padding: 150px 0px 0px;
}

.btm-bg {
  position: relative;
  bottom: 0px;
  width: 100%;
}

.btm-bg img {
  width: 100%;
}

.banner-btn .btn {
  margin-right: 10px;
}

.banner-2 {
  background: url("../images/bg/home-1.jpg") center center;
  background-size: cover;
  position: relative;
}

.banner-2:before {
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: #ECF1DF;
  opacity: .9;
}

/*=================
FEATURE Section
====================*/

.feature .container { padding: 0 50px;}

.feature-item {
  transition: 0.5s;
  position: relative;
  background: #fff;
  overflow: hidden;
  margin-bottom: 30px;
  align-items: center;
  text-align: center;
}

.feature-item .feature-img {
  width: 70px;
  height: 70px;
  margin: 25px auto;
  

}

.feature-item .feature-img img {
  
}

.feature-item .feature-content h4 {
  margin-bottom: 15px;
}

.feature-item p {
  margin-bottom: 0px;
  padding: 0 10px;
}

.feature-item:hover {
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
}

.feature-item:hover:before {
  width: 100%;
  background: #F08100;
}

/* power of ayurveda */ 

.powerofayur .service-item {

  border-radius: 300px;
  
  padding: 0;
  min-height: 550px;
  margin-bottom: 20px;

}
.powerofayur .service-img
{
  border-top-left-radius: 300px;
  border-top-right-radius: 300px;
  padding: 0;
  margin-bottom: 25px;
  background: rgba(0, 0, 0, 0.1)
}

.powerofayur .process-item { padding: 30px 50px; border-bottom: dashed 1px #ccc; width: 100%; clear: both;}
.powerofayur .process-item i{ color:#fff; background: #196433; border-radius: 50%; padding: 5px; margin-right: 10px;}
.powerofayur .process-text h3 { float: left; padding-top: 10px; margin-bottom: 40px;}
.powerofayur .process-icon img { border: dashed 1px #ccc; border-radius: 50%;}
.powerofayur .process-item .process-icon {
  float: left;
  width: 50px;
  height: 50px;
  color: #fff;
  padding-top: 0;
  border-radius: 100%;
  margin-right: 15px;
  
}
.powerofayur ul { clear: both; }
.powerofayur ul li { float: left;  line-height: 28px; margin: 0 0 10px 10px; color: #000;}
.pressrelease { padding-top: 100px;}
.pressrelease .blog-post {   min-height: 400px;   }  
.pressrelease .blog-post h5 { line-height: 26px; color: #000;}  


/*==================
Services
==================*/


.ayubg1 {
  background-color: #193a25; 
}
.ayubg2 {
  background-color:#196433; 
}
.ayubg3 {
  background-color: #4e8158; 
}
.ayubg4 {
  background-color: #80954a; 
}

.ayubg5 {
  background-color: #d0cc8d; 
}
.ayubg6 {
  background-color:#dabf90; 
}
.ayubg7 {
  background-color: #e68e7a; 
}
.ayubg8 {
  background-color: #db585e; 
}

.service {
  padding-bottom: 130px;
}

.service .service-img {
  margin: 0 auto 40px;
  border: 10px solid rgba(0, 0, 0, 0.05);
  width: 200px;
  height: 200px;
  position: relative;
  border-radius: 100%;
}

.service .service-img img {
  width: 100%;
}

.service .service-img span {
  position: absolute;
  right: 20px;
  width: 50px;
  height: 50px;
  background: #d12030;
  text-align: center;
  border-radius: 100%;
  padding-top: 10px;
  font-size: 18px;
  color: #fff;
  z-index: 2;
  display: none;
}

.service-item {
  text-align: center;
  transition: 0.5s;
  position: relative;
  
  padding: 50px 35px;
  /*background: #fff;
  border-radius: 178px;*/
}

.service-item h4 {
  transition: 0.5s;
}

.service-item:hover {
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
}

.service-item:hover .read-more {
  color: #d12030;
  border-color: #d12030;
}

.service-item:hover h4 {
  color: #d12030;
}

.service-item p {
  margin: 15px 0px 35px;
}

.service-item .btn {
  margin-bottom: -30px;
}

.read-more {
  color: #408858;
  border-bottom: 2px solid #408858;
  padding-bottom: 4px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
}

/* ===========================
 CTA BG
================================*/
.cta {
  background: url("../images/bg/banner-bg-2.jpg") center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 120px 0px 0px;
  position: relative;
  margin-bottom: 100px;
}

.cta .cta-content {
  background: #fff;
  padding: 50px;
  position: relative;
  border-left: 10px solid #408858;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  margin-bottom: -100px;
  border-radius: 8px;
}

.cta .cta-content h2 {
  font-size: 48px;
  margin-bottom: 30px;
  font-weight: 300;
  letter-spacing: -2px;
  line-height: 1.3;
}

.cta .cta-content h2 span {
  text-decoration: underline;
  -webkit-text-decoration-color: #F08100;
          text-decoration-color: #F08100;
  font-weight: 700;
}

/*=================
ABOUT Section
====================*/
.about {
  padding: 0 0px 0 0px;
}
.about .img-fluid { max-width: 75%;}
.about-img {
  position: relative;
  margin-right: 50px;
  margin-top: 30px;
}

.about-img:before {
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}

.about-img:after {
  position: absolute;
  top: -30px;
  width: 100%;
  height: 100%;
  right: -30px;
  border: 10px solid #ECF1DF;
  content: "";
  z-index: -1;
}

.about-img .video-play {
  width: 100px;
  height: 100px;
  display: inline-block;
  position: absolute;
  right: 25px;
  bottom: 25px;
  background: #fff;
  text-align: center;
  padding-top: 38px;
  color: #408858;
  font-size: 20px;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
}

.about-img .video-play:hover {
  background: #408858;
  color: #fff;
}

.about-img img {
  width: 100%;
}

.about-info {
  margin-bottom: 0px;
}

.about-info p {
  margin: 20px 0px 30px;
}

.about-info ul li {
  width: 49%;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 18px;
  font-weight: 600;
  color: #333;
}

.about-info ul li i {
  color: #408858;
  margin-right: 10px;
}

.about-info .btn {
  margin-top: 25px;
}

/*=================
Process Section
====================*/
.process-wrap {
  margin-top: 40px;
}

.process-item {
  margin-bottom: 30px;
  clear: both;
}

.process-item .process-icon {
  float: left;
  margin-right: 30px;
  width: 60px;
  height: 60px;
  
  text-align: center;
  color: #fff;
  padding-top: 0;
  border-radius: 100%;
  
}

.process-item .process-icon.style-2 {
  background: #408858;
}

.process-item .process-text {
  overflow: hidden;
}

.process-item .process-text h5 {
  margin-bottom: 10px;
  font-size: 20px;
}

.process-item p {
  margin-bottom: 0px;
}

/*=====================
Products section
====================*/
.product {
  padding-bottom: 70px;
}

.product-item {
  margin-bottom: 30px;
  text-align: center;
  padding: 40px 35px 35px 35px;
  border: 2px solid #eef2fb;
  border-radius: 4px;
  position: relative;
  transition: .4s;
}

.product-item:before {
  position: absolute;
  content: "";
  left: 0px;
  bottom: -2px;
  width: 40px;
  height: 3px;
  right: 0px;
  background: #408858;
  margin: 0 auto;
  opacity: 0;
  transition: .4s;
}

.product-item:hover {
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  background: #fff;
}

.product-item:hover:before {
  opacity: 1;
}

.product-item .product-img {
  margin-bottom: 50px;
}

.product-item .product-text h5 {
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 20px;
}

.product-item .price-info h6 {
  color: #408858;
  margin-bottom: 0px;
}

.product-item .price-info h6 span {
  text-decoration: line-through;
  color: #CCC8C8;
  margin-left: 5px;
}

.rating {
  margin-bottom: 10px;
}

.rating i {
  font-size: 13px;
}

.btn-text {
  text-align: center;
  margin-top: 70px;
}

.btn-text p {
  font-size: 20px;
  margin-bottom: 30px;
  color: #222;
}

/*==================
CTA-2
==================*/
.cta-2 {
  background: url("../images/bg/cta.jpg") center center;
  background-size: cover;
}

.cta-2 .section-heading {
  margin-bottom: 0px;
}

.cta-2 .section-heading .btn {
  background: #fff;
  border-color: #fff;
  color: #408858;
}

.cta-2 .section-heading .btn:hover {
  background: #F08100;
  border-color: #F08100;
  color: #fff;
}

.cta-2 .section-heading .subtitle {
  color: #fff;
}

.cta-2 .section-heading h2 {
  margin: 20px 0px 30px;
  color: #fff;
}

/*==================
    Order Processed
=========================*/
.order-booking {
  padding-bottom: 70px;
}

.order-item {
  border: 2px solid #eef2fb;
  padding: 30px 20px;
  position: relative;
  transition: .4s;
  margin-bottom: 30px;
}

.order-item.active, .order-item:hover {
  background: #eef2fb;
}

.order-item.active i, .order-item:hover i {
  background: #F08100;
  color: #fff;
}

.order-item h4 {
  margin-top: 20px;
  margin-bottom: 15px;
  font-size: 20px;
}

.order-item p {
  margin-bottom: 0px;
}

.order-item i {
  width: 50px;
  height: 50px;
  background: #ECF1DF;
  text-align: center;
  color: #F08100;
  padding-top: 13px;
  border-radius: 100%;
  position: absolute;
  top: -26px;
  right: 30px;
  transition: .4s;
  font-size: 20px;
  border: 2px solid #fff;
}

/*====================
 DEAL Section
 ==========================*/
.pb-190 {
  padding-bottom: 190px;
}

.deal-info.section-heading {
  margin-bottom: 0px;
  padding-left: 20px;
}

.deal-info.section-heading h2 {
  font-size: 75px;
  margin-bottom: 15px;
}

.deal-info.section-heading p {
  margin-bottom: 30px;
  font-size: 17px;
  padding: 0px 50px;
}

.deal-inner {
  background: #ECF1DF;
  padding: 60px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.02);
  border-radius: 5px;
  margin-top: -90px;
}

.deal-img {
  position: relative;
  z-index: 1;
}

.deal-img:before {
  position: absolute;
  content: "";
  top: 10px;
  right: -45px;
  width: 400px;
  height: 400px;
  background: #fff;
  z-index: -1;
  border-radius: 100%;
}

.deal-img .deal-coupon {
  position: absolute;
  right: -40px;
  top: 40px;
  background: #F08100;
  padding: 3px 14px;
  border-radius: 100%;
  font-weight: 700;
  color: #fff;
  width: 80px;
  height: 80px;
  font-size: 20px;
  text-align: center;
  padding-top: 13px;
  text-transform: uppercase;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
}

/*====================
Clients Carousel
=======================*/
.clients-carousel {
  padding: 50px 0px;
}

.clients-thumb {
  width: 100%;
  max-width: 10rem;
  height: auto;
  margin-right: auto;
  margin-left: auto;
}

/*============================== 
FOOTER section
===================== */
.footer {
  border-top: 2px solid #d12030;
}

.footer-top {
  padding: 80px 0px 30px;
}

.footer-btm {
  padding: 0;
}

.footer-btm p {
  margin-bottom: 0px;
}

.footer-about p {
  padding-right: 30px;
}

.footer-gallery ul li {
  width: 30%;
  float: left;
  margin: 3px;
}

.footer-gallery ul li img {
  border-radius: 5px;
}

.footer-widget .widget-title {
  margin-bottom: 0;
  font-size: 20px;
  position: relative;
  padding-bottom: 15px;
}

.footer-widget .widget-title:before {
  
  
}

.footer-contact ul li {
  line-height: 35px;
}

.footer-contact ul li i {
  margin-right: 10px;
  color: #408858;
}

.footer-contact ul li a {
  color: #6a7695;
}

.footer-contact ul li a:hover {
  color: #F08100;
  padding-left: 10px;
}

.copyright-bg {
  background: #d12030;
  padding: 5px 0px;
}

.footer-btm p {
  color: #fff;
}

.footer-btm-menu a {
  text-transform: uppercase;
  color: #333;
  font-size: 13px;
  padding-left: 20px;
}

.footer-btm-menu a:last-child {
  border-left: 1px solid #ddd;
  margin-left: 16px;
}

.footer-socials a {
  width: 40px;
  height: 40px;
  text-align: center;
  background: #f1f6fa;
  display: inline-block;
  border-radius: 100%;
  padding-top: 7px;
  color: #222;
}

.footer-socials a:hover {
  background: #408858;
  color: #fff;
}

/*=======================
Contact Form Page
=====================*/
.contact-section {
  padding-bottom: 70px;
}

.contact-item {
  border: 2px solid #eef2fb;
  padding: 35px 20px;
  text-align: center;
  flex-basis: 33.33%;
  margin: 0px 10px 30px;
  border-radius: 4px;
}

.contact-item p {
  margin-top: 20px;
  margin-bottom: 10px;
  text-transform: uppercase;
  color: #408858;
}

#contactForm .form-control {
  transition: all 0.3s ease-in-out;
  background: #eef2fb;
  height: 55px;
  border-radius: 35px;
  padding-left: 20px;
  border-color: transparent;
}

#contactForm .form-control:focus {
  background: transparent;
  border-color: #F08100;
}

#contactForm textarea.form-control {
  height: auto;
  border-radius: 15px;
}

.map {
  height: 350px;
  background: #eee;
}

/*===================
  TVC
=====================*/

#carouselTVC { width: 80%; margin: 0 auto;}

.carousel-control-prev-icon, .carousel-control-next-icon {
  width: 100px;
  height: 64px;
  

}

.carousel-control-prev, .carousel-control-next {
  opacity: 1;
}

.carousel-control-prev-icon {
  background-image: url('../images/bg/arrow-prev.png');
}

.carousel-control-next-icon {
  background-image: url('../images/bg/arrow-next.png');
}

/*===================
Team Section
================== */

.primary-bg {

  background: #fff url('../images/bg/tittle-bg1.png') 50% 65px no-repeat;
}

.secondary-bg {
  background: #fffced;
  background: #fffced url('../images/bg/tittle-bg.png') 50% 65px no-repeat;
}

.secondary1-bg {
  background: #f0c365;
  background: #f0c365 url('../images/bg/tittle-bg2.png') 50% 65px no-repeat;
}

.secondary-bg .subheading {
  z-index: 1;
}

.team-item {
  background: #fff;
  position: relative;
  margin-bottom: 30px;
}

.team-item:before {
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: #408858;
  opacity: 0;
  transition: all .4s;
}

.team-item:hover:before {
  opacity: .5;
}

.team-item:hover {
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
}

.team-item:hover ul {
  opacity: 1;
}

.team-info {
  position: relative;
  background: #fff;
  padding: 15px 20px;
  text-align: center;
  border-top: 2px solid #408858;
}

.team-info h4 {
  margin-bottom: 0px;
  font-size: 20px;
}

.team-info p {
  margin-bottom: 0px;
  color: #408858;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.team-info ul {
  opacity: 0;
  transition: all .4s;
  position: absolute;
  top: -56px;
  text-align: center;
  left: 0px;
  right: 0px;
}

.team-info a {
  width: 40px;
  height: 40px;
  display: inline-block;
  text-align: center;
  padding-top: 7px;
  border-radius: 100%;
  color: #F08100;
  background: #fff;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
}

.team-info a:hover {
  background: #408858;
  border-color: #408858;
  color: #fff;
}

/*=============================
 Service page
==========================*/
.feature-2 {
  padding: 50px 0px 0px;
}

.feature-item.style-2 {
  background: #F1F6FC;
  padding: 40px;
  display: block;
  text-align: center;
}

.feature-item.style-2 .feature-img {
  margin-right: 0px;
  margin-bottom: 30px;
}

/*================
Service-2
======================*/
.service-carousel .owl-theme .owl-dots .owl-dot.active span,
.service-carousel .owl-theme .owl-dots .owl-dot:hover span {
  background: #F08100;
}

.service-carousel .owl-dots {
  margin-top: 40px;
}

.service-item.style-2 {
  padding: 30px;
  border: 1px solid #F1F6FC;
}

.service-item.style-2 .service-img {
  margin-bottom: 25px;
  border: 0px;
  width: 200px;
  height: 200px;
  position: relative;
}

/*=======================
Testimonial
=========================*/
.review .review-item {
  padding: 40px 30px 30px 30px;
  background: #F1F6FA;
  text-align: center;
  border-radius: 20px;
}

.review .review-item i {
  font-size: 40px;
}

.review .review-item .desc {
  margin: 20px 0px 30px;
  color: #222;
  font-size: 18px;
  line-height: 36px;
}

.review.owl-carousel .owl-nav {
  position: absolute;
  left: 0px;
  left: -53%;
  bottom: 14%;
}

.review.owl-carousel .owl-nav button.owl-prev,
.review.owl-carousel .owl-nav button.owl-next {
  width: 50px;
  height: 50px;
  background: #F1F6FA;
  border-radius: 100%;
}

.review.owl-carousel .owl-nav button.owl-prev:hover,
.review.owl-carousel .owl-nav button.owl-next:hover {
  background: #408858;
  color: #fff;
}

.review.owl-carousel .owl-nav button.owl-prev {
  background: #408858;
  color: #fff;
}

.client-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.client-wrap .client-img {
  border-radius: 100%;
  overflow: hidden;
  max-width: 70px;
  margin-right: 20px;
  border: 5px solid #fff;
}

.client-info h6 {
  margin-bottom: 0px;
}

.client-info p {
  margin-bottom: 0px;
}

/*============

Fixed btm top
--------------*/
.fixed-btm-top {
  position: fixed;
  bottom: 60px;
  right: 60px;
}

.fixed-btm-top .scroll-to-top {
  display: inline-block;
  text-align: center;
  height: 50px;
  background: #408858;
  color: #fff;
  width: 50px;
  border-radius: 100%;
  padding-top: 10px;
  opacity: 0;
}

.fixed-btm-top .scroll-to-top:hover {
  background: #F08100;
  color: #fff;
}

.scroll-to-top.reveal {
  opacity: 1;
  visibility: visible;
}

/*===================
Blog Main section home
=====================*/
.blog-section {
  background: #f1f6fa;
  padding-bottom: 70px;
}

.blog-post {
  transition: .4s;
  background: #fff;
  margin-bottom: 30px;
}

.blog-post:hover {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.04);
  background: #fff;
}

.blog-post:hover .blog-img img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.blog-post .post-media {
  position: relative;
  transition: .4s;
  overflow: hidden;
}

.blog-post .post-media img {
  transition: .4s;
  width: 100%;
}

.blog-post .post-media span {
  position: absolute;
  right: 20px;
  top: 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.04);
  background: #fff;
  text-align: center;
  font-weight: 500;
  color: #222;
  font-size: 15px;
  text-transform: uppercase;
  line-height: 20px;
  display: inline-block;
  padding: 7px 20px;
  border-radius: 20px;
  z-index: 2;
}

.blog-post .post-content {
  padding: 20px 30px 30px 30px;
}

.blog-post .post-content .post-meta {
  border-bottom: 2px solid #eef2fb;
  padding-bottom: 15px;
}

.blog-post .post-content .post-meta span {
  margin-right: 14px;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  color: #6f7172;
  font-size: 12px;
}

.blog-post .post-content .post-meta span i {
  margin-right: 10px;
  color: #ddd;
}

.blog-post .post-content .post-title {
  margin: 18px 0px 13px;
  font-size: 26px;
  line-height: 1.4;
  letter-spacing: -.5px;
}

.blog-post .post-content .btn {
  padding: 13px 30px;
  margin-top: 15px;
}

.blog-post .post-content .btn:hover {
  background: #408858;
  border-color: #408858;
  color: #fff;
}

.blog-post:hover h2 {
  color: #F08100;
}

/*==================
Blog PAge 
==================== */
.main-content-area {
  padding: 100px 0px;
}

.post-bg {
  background: #faf9ff;
  border: 1px solid #eef2fb;
}

.post-bg .post-content {
  padding: 30px 40px 40px 40px;
}

/*-----------------
Right Sidebar
-----------------*/
.blog-sidebar .widget {
  padding: 30px;
  background-color: white;
  border: 2px solid #eef2fb;
  border-top-width: 4px;
  margin-bottom: 40px;
  transition: .4s;
  /* ----TAGS-----*/
  /*--------- SEARCH FORM -----------*/
  /*============== Widgte Categories ============ */
}

.blog-sidebar .widget:hover {
  box-shadow: 0px 8px 16px 0px rgba(200, 183, 255, 0.2);
  border-top-color: #8fb569;
}

.blog-sidebar .widget .widget-title {
  font-size: 20px;
  margin-bottom: 30px;
  position: relative;
  padding-bottom: 20px;
}

.blog-sidebar .widget .widget-title:before {
  position: absolute;
  content: "";
  bottom: -2px;
  left: 0px;
  width: 40px;
  height: 2px;
  background: #8fb569;
}

.blog-sidebar .widget ul, .blog-sidebar .widget ol {
  border: 0;
  list-style: none;
  margin: 0;
  padding: 0px;
}

.blog-sidebar .widget.widget_tag_cloud a {
  font-size: 13px;
  background: #f5f5f5;
  padding: 6px 16px;
  text-transform: uppercase;
  color: #333;
  margin: 0px 5px 10px 0px;
  display: inline-block;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.blog-sidebar .widget.widget_tag_cloud a:hover {
  background: #408858;
  color: #fff;
}

.blog-sidebar .widget.widget_search input {
  height: 55px;
  position: relative;
  border-radius: 0px;
  border: 1px solid #f5f5f5;
  background: #f5f5f5;
  padding: 9px 10px 9px 20px;
  width: 100%;
}

.blog-sidebar .widget.widget_search .search-form {
  position: relative;
}

.blog-sidebar .widget.widget_search .search-form [type=submit] {
  color: #fff;
  background: #408858;
  border: 1px solid #408858;
  padding: 12px 22px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
}

.blog-sidebar .widget.widget_search .search-form [type="submit"] {
  position: absolute;
  top: 0px;
  right: 0px;
  border-radius: 0px;
}

.blog-sidebar .widget.widget_categories ul li:first-child {
  border-top: 0;
  padding-top: 0;
}

.blog-sidebar .widget.widget_categories ul li {
  border-top: 1px solid #eaedff;
  padding: 15px 0;
  overflow: hidden;
  text-align: right;
  color: #647589;
}

.blog-sidebar .widget.widget_categories ul li a {
  display: block;
  color: #647589;
  float: left;
}

.blog-sidebar .widget.widget_categories ul li a i {
  margin-right: 10px;
}

.blog-sidebar .widget.widget_categories ul li a:hover {
  color: #408858;
}

/*==============
Recent post widget
====================*/
.recent-posts li {
  clear: both;
  margin-bottom: 15px;
  overflow: hidden;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding-bottom: 15px;
}

.recent-posts li:last-child {
  margin-bottom: 0px;
  border: 0px;
}

.widget-post-thumb {
  max-width: 80px;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 20px;
}

.widget-post-body h6 {
  margin-bottom: 0px;
}

.widget-post-body span {
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 1px;
}

/*=====================
Blog pagination
===================*/
.blog-pagination {
  margin-top: 60px;
}

.blog-pagination ul {
  list-style-type: none;
  margin-left: 0px;
  padding-left: 0px;
  clear: both;
  display: flex;
  align-items: center;
}

.blog-pagination ul li {
  margin-right: 10px;
}

.blog-pagination ul li.active a {
  color: #fff;
  background: #408858;
  border-color: #408858;
}

.blog-pagination ul li a {
  width: 50px;
  height: 50px;
  background: #fff;
  color: #408858;
  border: 1px solid #eee;
  text-align: center;
  padding-top: 10px;
  display: inline-block;
  font-weight: 700;
}

.blog-pagination ul li a:hover {
  background: #408858;
  color: #fff;
  border-color: #408858;
}

/*==========================
Single Blog Page 
===========================*/
.post-single h1, .post-single h2, .post-single h3, .post-single h4, .post-single h5, .post-single h6 {
  margin-bottom: 20px;
}

.post-single img {
  margin-bottom: 20px;
}

.post-single blockquote {
  padding: 40px 30px 40px;
  margin-top: 35px;
  line-height: 1.5;
  font-size: 24px;
  background: #8fb569;
  position: relative;
  padding-left: 120px;
  color: #fff;
}

.post-single blockquote:after {
  content: "\f10d";
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  position: absolute;
  font-size: 60px;
  opacity: 0.3;
  top: 35%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 30px;
  z-index: 1;
}

.post-single blockquote i {
  font-size: 30px;
  display: none;
}

.post-single blockquote cite {
  display: block;
  font-style: normal;
  margin-top: 20px;
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.post-single .post-meta {
  border-bottom: 2px solid #eef2fb;
  padding-bottom: 15px;
}

.post-single .post-meta span {
  margin-right: 14px;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  color: #6f7172;
  font-size: 12px;
}

.post-single .post-meta span i {
  margin-right: 10px;
  color: #ddd;
}

/*=====================
 Single Post Tags
==========================*/
.blog-footer {
  margin-top: 60px;
}

.single-tags a {
  display: inline-block;
  padding: 6px 16px;
  margin: 0px 5px;
  border: 2px solid #f0f0f0;
  color: #333;
  border-radius: 5px;
}

.single-tags a:hover {
  background: #F08100;
  color: #fff;
  border-color: #F08100;
}

.post-share a {
  width: 50px;
  height: 50px;
  background: #f5f5f5;
  text-align: center;
  padding-top: 12px;
  color: #777;
  display: inline-block;
  border-radius: 100%;
}

.post-share a:hover {
  background: #408858;
  color: #fff;
}

/*=====================
 Author Info
==========================*/
.author {
  margin: 40px 0px;
  padding: 40px;
  background: #f5f5f5;
  position: relative;
}

.author .author-img {
  width: 100px;
  height: 100px;
  float: left;
  margin-right: 25px;
}

.author .author-img img {
  border-radius: 100%;
  border: 5px solid #fff;
}

.author .author-info {
  overflow: hidden;
}

.author .author-info .author-designation {
  color: #F08100;
}

.author .author-info h4 {
  margin-bottom: 0px;
}

.author .author-info ul {
  margin: 25px 0px 0px;
}

.author .author-info ul li a {
  color: #222;
  padding: 0px 6px;
}

/*=====================
 CommentS Area
==========================*/
.comments {
  margin: 90px 0px;
}

.comments .commment-title {
  margin-bottom: 60px;
}

.comments .media {
  margin-bottom: 25px;
}

.comments .media img {
  border-radius: 100%;
}

.comments .media .media-body {
  padding-left: 20px;
}

.comments .media .media-body .media {
  margin-top: 35px;
}

.comments .media .media-body h5 span {
  font-size: 12px;
  padding-left: 10px;
  margin-left: 10px;
  font-family: "Montserrat", sans-serif;
  color: #F08100;
  border-left: 1px solid #eee;
}

.comments .media .reply-link {
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  float: right;
  text-transform: uppercase;
  color: #777;
  letter-spacing: 1px;
}

.comments .media .reply-link i {
  margin-right: 5px;
}

.comments .media .reply-link:hover {
  color: #F08100;
}

/*=====================
 Comment Forms
==========================*/
.comments-form {
  background: #f5f5f5;
  padding: 30px;
  border-radius: 15px;
}

.comments-form h3 {
  margin-bottom: 10px;
}

.comments-form .comment_form {
  margin-top: 30px;
}

.comments-form .comment_form .form-control {
  height: 50px;
  border-radius: 40px;
  padding: 15px 15px 15px 20px;
  font-size: 14px;
  border: 1px solid #eee;
}

.comments-form .comment_form textarea.form-control {
  height: auto;
  border-radius: 15px;
}

.comments-form .comment_form .btn {
  padding: 14px 30px 14px 30px;
  margin-top: 20px;
}

/*=====================
 Single POst Navigation
==========================*/
.blog-navigation {
  margin: 60px 0px;
  padding: 50px 0px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  clear: both;
}

.blog-navigation a {
  color: #222;
  font-size: 20px;
}

.blog-navigation a span {
  display: block;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
  color: #408858;
  letter-spacing: 1px;
  margin-bottom: 10px;
}

.blog-navigation .next-post {
  text-align: right;
  float: right;
}

.single-tags {
  margin-bottom: 20px;
}

.single-tags a {
  margin-bottom: 10px;
}

.post-share a {
  margin-bottom: 10px;
}

#carouselBanner{ width: 100% !important; overflow: hidden;}
.carousel-slide{ position: relative; width: 100%; height: 0; padding-bottom: 44.42%; overflow: hidden;}

.banner-hritik{ position: absolute; width: 32.66%; height: auto; transform-origin: 0 100%; left: 8%; bottom: 0;}
.red-bg{ position: absolute; width: 100%; /* height: 100%; */ height: 0%; padding-bottom:30%;/*  background-color: #150001; */ top: 50%; transform: translateY(-50%); /* border-radius: max(0px, min(180px, (100% - 400px + 1px) * 9999)) / 180px; */ /* overflow: hidden;  *//* border-radius: 16vw; */}
.dot-bg{ position: absolute; width: 100%; height: 100%; bottom: 0px; background:#150001 url(../images/banner/banner-bg.jpg); background-size: 100% 100%; overflow: hidden;  border-radius: 16vw;}

.banner-caption{ position: absolute; width: 25.66%; height: auto; right: 33.53%; top: 6%;}
.power8{ position: absolute; width: 12%; height: auto; left: 50.5%; bottom:0%; margin-left: -6%;  margin-bottom: -4.25%;}
.banner-product{ position: absolute; width: 20.56%; height: auto; right: 18.8%; bottom: 15%; transform-origin: 100% 100%;}

.logo-left{ position: absolute; width: 3.78%; height: auto; left: 10%; top:8%;}
.logo-right{ position: absolute; width: 10.21%; height: auto; right: 0%; top:50%; transform: translateY(-50%);}

.tagline { position: absolute; width: 40.57%; height: auto; right: 10%; bottom:3%; transform-origin: 100% 100%;}

#effect{ position: absolute; left: 0; top: 0; right: 0; bottom: 0; width: 100% !important; height: 100% !important;}
#effect canvas{ position: absolute; left: 0; top: 0; right: 0; bottom: 0; width: 100% !important; height: 100% !important;}

.dotring{ width: 110%; height: auto; text-align: center; position: absolute; z-index: 0; margin: 0 auto; /* left: 50%; top: 50%; transform: translate(-50%, -50%); */ box-sizing: border-box; transform-origin: 50% 50%; left: 50%; top: 50%; transform: translate(-50%, -50%);}
.dotring img{ position: relative; width: 100%; height: auto; /* height: 100%; object-fit: cover; */ left: 0; top: 0; right: 0; bottom: 0; opacity: 0.6;} 

#homeVideo { min-width: 100%; min-height: 100%; width: 100%; height: auto; position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%);}
@media (max-width: 1024px) {
  .buybutton { right: 10px;}
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem;
    display: block;
    margin-bottom: 5px;
}
}

@media (max-width: 992px) {
  .navbar {
    padding: 0 10px;
  }
  .navbar-toggler { font-size: 2rem;}
  .navbar-brand { display: block; padding: 15px 0;}
  .buybutton { right: 100px; top: 20px;}
  .buybutton a { color: #fff;}
  .navbar-nav .nav-link { color: #fff;
    padding: 14px 0 !important;
  }
  .logotop { display: none;}
  #navbarMenu .ml-auto, .mx-auto { margin-left: 0 !important;}
  #navbarMenu .text-right { text-align: left !important;}
  .nav-item  { border-bottom: dashed 1px #666;}
  .navbar-nav { width: 100%;}
  .site-navigation { background: #d12030; }
  .navbar-toggler { color: #fff;}
  .nav-item {    border-bottom: dashed 1px #fff;}
  .banner .banner-content h1 {
    font-size: 70px;
  }
  .socialbutton { display: none;}
  .faqsHeader a { padding-right: 30px;}
  .faqsHeader {  padding-right: 0; }
  .banner .banner-content p {
    padding-right: 0px;
  }
  .navbar-nav li.active a {  color: #f6e16f !important;}
  .about-info {
    margin-top: 40px;
  }
  .ayurveda-img { width: 150px; height: 150px;     line-height: 150px;}
  .roundedayurveda ul { padding-top: 30px;}
  .roundedayurveda ul li { margin-bottom: 5px; font-size: 0.9em;}
  .ayurveda-left ul li {    padding-left: 0;}
  .ayurveda-right ul li {     padding-right: 0;}
  .powerofayur .process-item { padding: 30px 20px;}
  .powerofayur .process-text h3 { float: none;}
  #shonow span { border-radius: 50px; border: dashed 1px  #fff;  font-weight:700; display: inline-block; padding: 0 10px;}
  .service-item {
    margin-bottom: 65px;
  }
  .about-content {
    margin-top: 40px;
  }
  .deal-info.section-heading {
    padding-left: 0px;
    margin-top: 60px;
  }
  .banner-img img {
    max-width: 100%;
    height: auto;
  }
  .author .author-img {
    float: none;
    margin-bottom: 20px;
  }
  .comments .media {
    display: block;
  }
  .comments .media .media-body {
    padding-left: 0px;
  }
  .blog-navigation .next-post {
    text-align: left;
    float: none;
    margin-top: 30px;
  }
  .blog-navigation a {
    display: block;
  }
  .border-right {
    border-color: transparent !important;
  }
  .service-item.style-2 {
    margin-bottom: 20px;
  }

  

}

@media(max-width:768px){
  .formobile { display: block !important;}
  .fordesk { display: none !important;}
  .banner .pt-5 {  padding-top: 2rem !important;}

  

}


@media (max-width: 480px) {
  h1 { font-size: 1.5em;}
  .carousel-control-prev-icon, .carousel-control-next-icon {
    width: 50px;
    height: 32px;
  }
  .section-padding { padding:50px 0px;}
  .primary-bg {background: #fff url(../images/bg/tittle-bg1.png) 50% 15px no-repeat;}
  .secondary-bg { background: #fffced; background: #fffced url(../images/bg/tittle-bg.png) 50% 15px no-repeat; }
  .secondary1-bg {  background: #f0c365; background: #f0c365 url(../images/bg/tittle-bg2.png) 50% 15px no-repeat; }
  .buybutton { display: none;}
  .banner .banner-content h1 {
    font-size: 35px;
  }
  .primary-bg {    background: #fff url(../images/bg/tittle-bg1.png) 50% 30px no-repeat; background-size: 500px 78px;} 
  .secondary-bg {    background: #fffced url(../images/bg/tittle-bg.png) 50% 30px no-repeat; background-size: 500px 78px;} 
  .secondary1-bg {    background: #f0c365 url(../images/bg/tittle-bg2.png) 50% 30px no-repeat; background-size: 500px 78px;} 
  .feature-item .feature-img {
    margin-bottom: 30px;
    width: 80px;
    height: 80px;
    
  }
  .about { padding: 0;}
  .tohide { display: none;}
  .page-header:before { top: 82px;}
  .mobilecenter {text-align: center !important; padding-bottom: 15px;}
  .banner-btn .btn {
    margin-bottom: 10px;
  }
  .formobile { display: block !important;}
  .fordesk { display: none !important;}
  .banner .mt-5 { margin-top: 1.5rem !important;}
  .powerofayur .process-text h3 { font-size: 18px; float: none;}
  .powerofayur .process-text { display: block; float: none;}
  .powerofayur .process-item { padding-top: 20px;}
  .roundedayurveda ul { padding-top: 0;}
  .roundedayurveda ul li { 
    margin-bottom: 5px;
    text-align: left;
    padding: 5px 0 10px 0;
    font-size: 1em;
    line-height: 40px;
    background: #fff;
    border-radius: 40px;
    font-size: 1.2em;
    letter-spacing: 1px;
  }
  .roundedayurveda ul li img { float: left; height: 50px; width: 50px; margin: 0 25px;}
  .ayurveda-left ul li {    padding-left: 0;}
  .ayurveda-right ul li {     padding-right: 0;}
  .roundedayurveda ul li::after { content: none;
  }

.tohideWith, .ayurveda-img { display: none;}
.pressrelease .blog-post {   min-height: auto;   }  
  .section-heading h2 {
    font-size: 30px;
  }
  .section-heading .subheading {
    font-size: 72px;
    top: -8px;
  }
  .shop-now-scroller {
    bottom: 10px;
    font-size: 0.8em;
    height: 50px;
    width: 60px;
  }
  .shop-now-scroller i { padding: 0 0 0 0 !important; }
  .about-info ul li {
    width: 100%;
  }
  .cta .cta-content {
    padding: 30px;
  }
  .cta .cta-content h2 {
    font-size: 26px;
  }
  .deal-img::before {
    position: relative;
  }
  .deal-info.section-heading h2 {
    font-size: 38px;
  }
  .deal-info.section-heading p {
    padding: 0px;
  }
  .feature-item {
    display: block;
  }
  
  .author .author-img {
    float: none;
    margin-bottom: 20px;
  }
  .comments .media {
    display: block;
  }
  .comments .media .media-body {
    padding-left: 0px;
  }
  .blog-navigation .next-post {
    text-align: left;
    float: none;
    margin-top: 30px;
  }
  .blog-navigation a {
    display: block;
  }
  .border-right {
    border-color: transparent !important;
  }
}

@media (max-width:320px) {
  .float-right { float: none !important; display: inline-block; margin-top: 5px;}
.list-group-item { text-align: center;}
}

@media (max-width: 568px) {

  .roundedayurveda ul { padding-top: 0;}
  .roundedayurveda ul li { 
    margin-bottom: 5px;
    text-align: left;
    padding: 5px 0 10px 0;
    font-size: 1em;
    line-height: 40px;
    background: #fff;
    border-radius: 40px;
    font-size: 1.2em;
  }
  .roundedayurveda ul li img { float: left; height: 50px; width: 50px;}
  .ayurveda-left ul li {    padding-left: 0;}
  .ayurveda-right ul li {     padding-right: 0;}
  .roundedayurveda ul li::after { content: none;
  }
  .tohideWith, .ayurveda-img { display: none;}
}